import React from 'react'
import Nav from 'react-bootstrap/Nav'
import classNames from 'classnames'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import GenericLink from '../../shared/Link'
import {MenuLink} from '../../../types/content-types'

type Props = {
  doormatButtonMenuItems?: MenuLink[]
}

const ButtonDoormatNavigation: React.FC<Props> = ({
  doormatButtonMenuItems,
}: Props) => (
  <Nav className="c-footer__doormat--buttonDoormatNavWrapper">
    <Row>
      {doormatButtonMenuItems
        ?.filter(el => el.attributes.enabled)
        .sort((a, b) => a.attributes.weight! - b.attributes.weight!)
        .map(buttonMenuItem => {
          const btnClass = classNames('btn border-0 py-2 my-2 w-100', {
            'btn--primary':
              buttonMenuItem.attributes.field_button_type === 'primary',
            'btn-secondary':
              buttonMenuItem.attributes.field_button_type === 'secondary',
          })
          const textClass = classNames('ma', {
            'text-white':
              buttonMenuItem.attributes.field_button_type === 'primary',
            'text-purple':
              buttonMenuItem.attributes.field_button_type === 'secondary',
          })
          return (
            <Col md={10} key={buttonMenuItem.id}>
              <Button className={btnClass} key={buttonMenuItem.id}>
                <GenericLink
                  extraClass={textClass}
                  link={buttonMenuItem.attributes.link}
                >
                  {buttonMenuItem.attributes.title}
                </GenericLink>
              </Button>
            </Col>
          )
        })}
    </Row>
  </Nav>
)

export default ButtonDoormatNavigation
